<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <Player :show-pose-controls="false"/>
      </div>
      <div class="col-md-8">
        <h2>News</h2>
      </div>
    </div>
  </div>
</template>
<script>
import Player from "@/components/player/Player";

export default {
  components: {Player},
  mounted() {
    this.$router.push('play/mining')
  }
}
</script>